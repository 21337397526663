<template>
  <div class="container">
    <nav-bar />
    <div class="header">
      <div class="login-type">
        <div class="type-title">账号密码登录</div>
      </div>

      <div class="login-form">
        <phone-input :needCode="false" />
        <pwd-input />
        <Submit
          ref="submit"
          :disBtn="$checkBtn(form)"
          @submit="toLogin()"
          btnStr="立即登录"
        />
        <div class="c949 fs26 tr mt40 other-tips">
          <router-link class="fl reg" to="/reg">注册账号</router-link>
          <router-link class="c494 forget-pass" to="/forgot">忘记密码？</router-link>
        </div>
        <Clause />
      </div>
    </div>
    <Footer pageType="pwd" />
  </div>
</template>

<script>
import NavBar from '@/components/navbar';
import PhoneInput from '@/components/phoneInput';
import PwdInput from '@/components/pwdInput';
import Submit from '@/components/submit';
import Footer from '@/components/footer';
import Clause from '@/components/clause';
import { login } from '@/api/login';
import { startTrackApp } from '@/tongji';
export default {
  name: 'pwdLogin',
  components: {
    NavBar,
    PhoneInput,
    PwdInput,
    Submit,
    Footer,
    Clause,
  },
  data() {
    return {
      form: {
        mobile: '',
        password: '',
      },
    };
  },
  methods: {
    //登录
    toLogin() {
      login(this.form).then((res) => {
        this.$loginCb(res.data.access_token)
        this.$refs.submit.loading = false
      }).catch(() => {
        this.$refs.submit.loading = false
      })
    }
  },
  mounted() {
    startTrackApp('账密登录');
  },
};
</script>
